/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import * as Sentry from '@sentry/browser';
import '../stylesheets/application.scss';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://80448e1f433c413bac9ff91282cf436e@o374876.ingest.sentry.io/5193555',
  });
}

const application = Application.start();
const context = require.context('./controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
